<!--label class="wrapper-label">Active</label-->
<div class="d-flex">
  <div class="flex-grow-1">
    <div class="container-fluid p-0">
      <div class="d-flex flex-row position-relative">
        <div class="textinput-group search w-100" [ngClass]="{ 'hide-search': showSpinner, 'z-index-top-up': values && values.length > 0 }">
          <input
            class="textinput-group__textinput focusable"
            autocomplete="noAutocomplete"
            [disabled]="limit && selectedValues?.length == limit"
            [attr.aria-invalid]="showError"
            (blur)="onBlur()"
            (focus)="onFocus(); hideSuggestions = false"
            [(ngModel)]="searchText"
            [class.error_msg]="showError"
            [class.error]="showError"
            [ngModelOptions]="{ standalone: true }"
            [id]="'search' + ctrl_id"
            [attr.aria-label]="showError ? meta.errorMsg : 'search ' + meta.title + (meta.info ? meta.info : '')"
            (ngModelChange)="stChanged($event)"
            [placeholder]="placeholder"
          />
          <label class="textinput-group__label" [ngClass]="{ focus: isFocus, asterisk: meta?.required }" [for]="'search' + ctrl_id">{{
            meta.title
          }}</label>
          <div *ngIf="values && values.length > 0 && !hideSuggestions" class="search__result">
            <div class="search__result__people max-dd-height">
              <a *ngFor="let v of values" (mousedown)="add(v); $event.preventDefault()" href class="search__result__item">
                <div>
                  <div class="title text-transform-none mb-2">{{ v[labelName] }}</div>
                  <div *ngIf="labelName2 && labelName2.length > 0" role="list" class="desc text-transform-none">{{ v[labelName2] }}</div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <label *ngIf="showError" class="textinput__errmsg"><i class="err_icon icon-ico_error_toast"></i>{{ meta.errorMsg }}</label>
        <div *ngIf="showSpinner" class="spinner__icon spinner__icon--medium"></div>
      </div>
    </div>
    <div>
      <ng-container *ngIf="selectedItemTemplate; else defaultSelected">
        <ng-container *ngTemplateOutlet="selectedItemTemplate; context: { $implicit: _selectedValues }"></ng-container>
      </ng-container>
      <ng-template #defaultSelected>
        <ul class="d-flex w-100 p-0 flex-column m-0">
          <li class="d-flex w-100 p-4 border-bottom" *ngFor="let sv of selectedValues; index as i">
            <span class="flex-grow-1 fonts-col__sub-nav-default-state">{{ sv[labelName] }}</span>
            <i role="button" [title]="deleteTitle" (click)="removeItem(sv)" class="emp-icon icon-ico_delete"></i>
          </li>
        </ul>
      </ng-template>
    </div>
  </div>
  <div class="tooltip--holder" *ngIf="tooltipPadding">
    <ey-input-tooltip [toolTipContent]="meta.info"></ey-input-tooltip>
  </div>
</div>
