<!--label class="wrapper-label">Active</label-->
<div class="d-flex">
  <div class="flex-grow-1">
    <div class="dropdown dropdown--single-select" [ngClass]="{ dropup: dropUp }">
      <a
        aria-expanded="false"
        aria-haspopup="true"
        class="dropdown-toggle"
        data-bs-toggle="dropdown"
        href
        id="single-select-active"
        role="button"
        (click)="toggleExpand(); $event.preventDefault()"
      >
        <input
          autocomplete="noAutocomplete"
          [id]="'mdd' + ctrl_id"
          class="textinput-group__textinput focusable"
          [readonly]="!searchable"
          [attr.aria-invalid]="showError"
          [attr.aria-label]="showError ? meta.errorMsg : meta.title + (meta.info ? meta.info : '')"
          [placeholder]="getPlaceHolderValue()"
          [ngModel]="selectedValues == (null || 0) ? setInputValue() : selectedValues?.length"
          (ngModelChange)="filter($event)"
          [ngClass]="{
            darkBkg: darkMode,
            searchHover: darkMode,
            error: showError,
            noPaddingTop: selectedValues?.length === 0 && floatingLabels,
            'dark-placeHolder': showDarkPlaceHolder,
          }"
        />
        <label
          *ngIf="hidePlaceholder()"
          [class.darkBkgLabel]="darkMode"
          class="textinput-group__label focus"
          [for]="'mdd' + ctrl_id"
          [ngClass]="{ asterisk: meta?.required }"
          >{{ meta.title }}</label
        >
        <i class="material-icons dropdown__expand_less" [class.darkBkgIco]="darkMode">expand_less</i>
        <i class="material-icons dropdown__expand_more" [class.darkBkgIco]="darkMode">expand_more</i>
      </a>
      <label *ngIf="showError" class="textinput__errmsg"><i class="err_icon icon-ico_error_toast"></i>{{ meta.errorMsg }}</label>
      <div
        [class.scroll-bar-custom]="showDarkScroll"
        [class.borderCustom]="darkMode"
        aria-labelledby="multi-select-flatlist-type-ahead"
        class="dropdown-menu mb-auto w-100"
        [ngClass]="{ show: isExpanded }"
      >
        <div class="">
          <label
            *ngFor="let v of filteredValues"
            [class.darkBkg]="darkMode"
            class="dropdown-item dropdown-item-custom checkbox checkbox--all w-100"
            data-value="{{ v[labelName] }}"
          >
            <input class="checkbox__input" type="checkbox" [ngModel]="isChecked(v)" (change)="changed(v)" />
            <span [class.darkBkgCb]="darkMode" class="checkbox__checkmark"></span>
            <p [class.darkBkgText]="darkMode" class="checkbox__label eyfontlight">{{ v[labelName] }}</p>
          </label>
        </div>
      </div>
    </div>

    <div *ngIf="showFilterPills">
      <ey-filter-pills
        [showLargeBadges]="showLargeBadges"
        [selectedValues]="selectedValues"
        [labelName]="labelName"
        [showClearAllOption]="showClearAllOption"
        (clearAllSelected)="onClearAll()"
        (removeItemClick)="removeItem($event)"
        [isDisabled]="isDisabled"
      ></ey-filter-pills>
    </div>
  </div>

  <div class="tooltip--holder" *ngIf="toolTipVisible">
    <ey-input-tooltip [toolTipContent]="meta.info"></ey-input-tooltip>
  </div>
</div>
