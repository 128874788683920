import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
export const FILE_UPLOAD_ENDPOINT = 'file';

export interface FileUploadResult {
  fileId: string;
  tempId: string;
  fileName?: string;
  visible?: boolean;
}

export const FileDescriptor = {
  Other: 'Other',
  ExcelAIVersion: 'ExcelAIVersion',
  ExcelAIPreview: 'ExcelAIPreview',
  ExcelAIResponse: 'ExcelAIResponse',
};

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private http: HttpClient) {}

  createFormData(file: File, fileId: string, fileDescriptor = FileDescriptor.Other, connectedEntityId = ''): FormData {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('tempId', fileId);
    formData.append('fileDescriptor', fileDescriptor);
    formData.append('connectedEntityId', connectedEntityId);

    return formData;
  }

  postFormData(formData: FormData): Observable<FileUploadResult> {
    return this.http.post<FileUploadResult>(`${environment.baseUrl}/${FILE_UPLOAD_ENDPOINT}`, formData);
  }

  removeFile(id: string, fileDescriptor: string = null): Observable<boolean> {
    return fileDescriptor
      ? this.http.delete<boolean>(`${environment.baseUrl}/${FILE_UPLOAD_ENDPOINT}/${id}/${fileDescriptor}`)
      : this.http.delete<boolean>(`${environment.baseUrl}/${FILE_UPLOAD_ENDPOINT}/${id}`);
  }
}
