<div class="d-flex">
  <div hieroglyphicInputSupport class="textinput-group flex-grow-1" [class.d-flex]="showSearchIcon">
    <input
      autocomplete="noAutocomplete"
      [readOnly]="readOnly"
      (blur)="onBlur()"
      (input)="onInput()"
      [disabled]="isDisabled"
      [id]="'inp' + ctrl_id"
      class="textinput-group__textinput focusable"
      [ngClass]="{ error: !disableErrorEvaluation && showError, warning: warning, white: isWhite }"
      [attr.aria-label]="!disableErrorEvaluation && showError ? meta?.errorMsg : meta?.title + (meta?.info ? meta?.info : '')"
      [attr.aria-invalid]="!disableErrorEvaluation && showError"
      [attr.maxlength]="maxlength"
      [type]="type"
      (focus)="onFocus()"
      [(ngModel)]="selectedValue"
      [attr.list]="list"
    />
    <i *ngIf="showSearchIcon" class="material-icons align-search">search</i>

    <label class="textinput-group__label focus" [for]="'inp' + ctrl_id" [ngClass]="{ asterisk: meta?.required }">{{ meta?.title }}</label>
    <div *ngIf="!disableErrorEvaluation">
      <label
        *ngIf="showError && !noErrorLabel"
        class="textinput__errmsg"
        [class.change-error-display-position]="changeErrorDisplayPositionForLongMessages"
        ><i class="err_icon icon-ico_error_toast"></i>{{ meta.errorMsg }}</label
      >
    </div>
  </div>
  <div class="tooltip--holder" *ngIf="extra !== 'none'">
    <ey-input-tooltip *ngIf="extra === 'tooltip'" [toolTipContent]="meta.info"></ey-input-tooltip>
    <ey-copy-to-clipboard [content]="selectedValue" *ngIf="extra === 'clipboard-copy'"></ey-copy-to-clipboard>
  </div>
</div>
