import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { switchMap, takeUntil } from 'rxjs/operators';
import { FlowType, ModuleFlowService } from 'src/app/designer/preview/module-flow.service';
import { BasePageContent } from '../base-page-content';
import { PropertyInput } from '../../property-input.model';
import { EyFileUploadComponent, FileListItem, FileUploadStatus } from 'src/app/shared/components/ey-file-upload/ey-file-upload.component';
import { FileUploadResult, FileUploadService } from 'src/app/core/file-upload.service';
import { ExtensionTypes, EyFileUploadService, FileTypes } from 'src/app/shared/components/ey-file-upload/ey-file-upload.service';
import { ButtonClass } from 'src/app/shared/components/ey-button/ey-button.component';
import { SaveNotificationServiceEndUserForm } from '../../save-notification-service-end-user-form.service';
import { EyAppSpinnerService } from 'src/app/shared/components/ey-app-spinner/ey-app-spinner.service';
import { FileDownloadService } from 'src/app/core/services/file-download.service';
import { saveAs as importedSaveAs } from 'file-saver';

export const UPLOAD_PAGE_META = {
  item: {
    title: 'Enter your text',
    errorMsg: 'Text should not be empty',
    required: true,
  },
};

const DRAG_AND_DROP_TITLE_IMAGE_MSG = 'Drag and drop image (.jpg, .jpeg, .png) or';
const DRAG_AND_DROP_TITLE_FILE_MSG = 'Drag and drop file or';

const DRAG_AND_DROP_SUBTITLE_IMAGE_MSG = 'One file allowed.';
const DRAG_AND_DROP_SUBTITLE_FILE_MSG = 'You can upload up to 20 files e.g. images, documents, archives - ';

export const ITEM_PROP_NAME = 'Items';
@Component({
  selector: 'app-upload-page.d-flex.flex-fill',
  templateUrl: './upload-page.component.html',
  styleUrls: ['./upload-page.component.scss'],
})
export class UploadPageComponent extends BasePageContent implements OnInit, AfterViewInit {
  docType: ExtensionTypes;
  btnClass = ButtonClass;
  allowAnyFileOnPreview = false;
  isRequired = false;
  fieldMeta = UPLOAD_PAGE_META;
  itemsPropertyName = ITEM_PROP_NAME;
  uploadedFiles: FileUploadResult[] = [];
  files: FileListItem[] = [];
  items: any[] = [];
  hasError = false;
  dragAndDropTitle = '';
  dragAndDropSubTitle = '';

  fileExtension = [FileTypes.excel, FileTypes.word, FileTypes.powerPoint];

  @ViewChild(EyFileUploadComponent) fileUploadCmp: EyFileUploadComponent;
  maxFiles: number;
  throwError = false;

  constructor(
    flowService: ModuleFlowService,
    protected spinnerService: EyAppSpinnerService,
    private eyFileUploadService: EyFileUploadService,
    private saveNotification: SaveNotificationServiceEndUserForm,
    private fileUploadService: FileUploadService,
    private fileDownloadService: FileDownloadService,
  ) {
    super(flowService, spinnerService);
  }

  ngOnInit(): void {
    this.flowService.responseHeaderMobileViewChange.pipe(takeUntil(this.destroy$)).subscribe((mobileViewChange) => {
      this.isMobileBreakpoint = mobileViewChange;
    });

    // TODO SO: refactor this quick fix to a normal operation
    if (this.flowType === FlowType.response && this.saveNotification.saveCurrentProperties$.observers.length === 0) {
      this.saveNotification.saveCurrentProperties$
        .pipe(
          switchMap(() => this.flowService.saveCurrent(this.page.moduleFlowPage.id, this.getProperties())),
          takeUntil(this.destroy$),
        )
        .subscribe((response) => {
          this.flowService.transformCurrentPageData(response);
          this.page = response;
          this.initPage();
        });
    }

    this.initPage();
  }

  initPage(): void {
    this.dragAndDropTitle = this.page.moduleFlowPage.isImage ? DRAG_AND_DROP_TITLE_IMAGE_MSG : DRAG_AND_DROP_TITLE_FILE_MSG;
    this.dragAndDropSubTitle = this.page.moduleFlowPage.isImage ? DRAG_AND_DROP_SUBTITLE_IMAGE_MSG : DRAG_AND_DROP_SUBTITLE_FILE_MSG;
    this.isRequired = this.page.moduleFlowPage.isRequired;
    if (!this.page.moduleFlowPage.isImage) {
      this.allowAnyFileOnPreview = true;
      this.docType = ExtensionTypes.anyFile;
      this.maxFiles = 20;
    } else {
      this.allowAnyFileOnPreview = false;
      this.docType = ExtensionTypes.imageFile;
      this.maxFiles = 1;
    }
    const pageProps = this.page.moduleFlowPage?.properties as Array<any>;
    const itemsProp = pageProps.find((p) => p.name === this.itemsPropertyName);
    this.items = itemsProp ? itemsProp?.items : [];
    if (this.items === null) {
      this.items = [];
    }

    if (Array.isArray(this.items)) {
      this.items.forEach((item) => {
        this.files.push({
          file: {
            name: item.name,
            lastModified: 0,
            size: 0,
            type: null,
            arrayBuffer: null,
            slice: null,
            stream: null,
            text: null,
            webkitRelativePath: '',
          },
          fileId: item.tempId,
          status: FileUploadStatus.uploaded,
          fileExtension: this.eyFileUploadService.getFileExtension(item.name, this.docType),
        });
        this.uploadedFiles.push({
          fileId: item.fileId,
          tempId: item.tempId,
          visible: true,
        });
      });
    }
  }

  ngAfterViewInit(): void {
    window.scroll(0, 0);
  }

  onValueChange(): void {
    this.saveNotification.dispatchNotSaved();
    if (this.flowType === FlowType.response && this.isAutoSave) {
      this.saveNotification.saveCurrentProperties$.next();
    }
  }

  createItem(item: any): UntypedFormControl {
    return new UntypedFormControl(item, [Validators.required]);
  }

  upload(file: FileListItem): void {
    this.throwError = false;
    const fd = this.fileUploadService.createFormData(file.file, file.fileId);
    this.fileUploadService
      .postFormData(fd)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (fupResult) => {
          fupResult.visible = true;
          this.uploadedFiles.push(fupResult);
          this.fileUploadCmp.markFileAsUploaded(fupResult);
          this.items.push({ fileId: fupResult.fileId, name: file.file.name, type: file.file.type, tempId: fupResult.tempId });
          this.onValueChange();
        },
        (error) => {
          console.error(error);
        },
      );
  }

  deleteFile(file: any): void {
    const deletedItem = this.uploadedFiles.find((f) => f.tempId === file.fileId);
    this.items = this.items.filter((f) => f.tempId !== file.fileId);
    this.uploadedFiles = this.uploadedFiles.filter((f) => f.tempId !== file.fileId);
    this.fileUploadCmp.delete(file);
    if (deletedItem) {
      this.fileUploadService
        .removeFile(deletedItem.fileId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (fupResult) => {
            this.onValueChange();
          },
          (error) => {
            console.error(error);
          },
        );
    }
  }

  downloadFile(file: FileListItem): void {
    this.spinnerService.withLoadingIndicator(this.fileDownloadService.getFile(file.uploadResult.fileId), this.destroy$).subscribe((x) => {
      importedSaveAs(x.body, file.uploadResult.fileName ?? file.file.name);
    });
  }

  getProperties(): Array<PropertyInput> {
    let props: Array<PropertyInput> = null;
    props = this.items ? [{ name: this.itemsPropertyName, value: JSON.stringify(this.items) }] : null;
    return props;
  }

  onPrev(): void {
    const loadingResult$ = this.spinnerService.withLoadingIndicator(this.flowService.doPrev(this.getProperties(), this.flowType), this.destroy$);
    loadingResult$.subscribe();
  }

  onNext(): void {
    if (this.page.moduleFlowPage.isRequired && this.uploadedFiles.length === 0) {
      this.throwError = true;
    } else {
      const loadingResult$ = this.spinnerService.withLoadingIndicator(this.flowService.doNext(this.getProperties(), this.flowType), this.destroy$);
      loadingResult$.subscribe();
    }
  }
}
