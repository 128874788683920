import {
  RectangularTool,
  RectToolType,
  Colors,
  createRectangularTool,
  drawSquareTool,
  fillAndStroke,
  drawConnectors,
  drawInvalidToolIcon,
} from './rectangular-tool';
import { PropertyPlacement, PropertyType } from '../domain/property';

const WIDTH = 120;
const HEIGHT = 60;
const BORDER_RADIUS = 30;
const PADDING = 4;

export const START_TOOL_NAME = 'Start Tool';

const colors: Colors = {
  selectedFillColor: '#ffffff',
  hoverFillColor: '#ffffff',
  fillColor: '#ffffff',
  fontColor: '#2E2E38',
};

export const properties = [
  {
    label: 'Body',
    name: 'body',
    placement: PropertyPlacement.Body,
    type: PropertyType.RichText,
    options: {
      placeholderText: '<p style="white-space: normal">&lt;Place your content here or click &#39;Reset to Default Content&#39;&gt;</p>',
      defaultContent: '<p><h2 style="text-align:center;">Welcome</h2></p><p style="text-align:center; font-size: 18px;">Click [Start] to begin.</p>',
    },
  },
];

export function draw(rect: RectangularTool, ctx: CanvasRenderingContext2D): void {
  ctx.save();
  ctx.lineWidth = 2;
  ctx.fillStyle = rect.colors.fillColor;

  drawSquareTool(rect, ctx, BORDER_RADIUS, PADDING, fillAndStroke, fillAndStroke);

  ctx.fillStyle = rect.colors.fontColor;
  //ctx.font = '12px EYInterstate-Bold';
  ctx.font = '12px Arial';
  ctx.textAlign = 'center';
  ctx.fillText('START', rect.x + WIDTH / 2, rect.y + (HEIGHT + 6) / 2), ctx.restore();

  if (rect.isInvalid) {
    drawInvalidToolIcon(rect, ctx);
  }

  drawConnectors(rect, ctx);
}

export function createStartTool(
  id: string,
  x: number,
  y: number,
  name: string,
  isSelected: boolean = false,
  isHovered: boolean = false,
): RectangularTool {
  return createRectangularTool(id, x, y, RectToolType.StartTool, isSelected, colors, isHovered, name, properties, WIDTH, HEIGHT);
}
